import type { LoraModel, PromptSegment } from '../types';

export function parsePromptSegments(prompt: string, models: LoraModel[]): PromptSegment[] {
  const segments: PromptSegment[] = [];
  let currentText = '';
  
  const words = prompt.split(/\s+/).filter(word => word.length > 0);
  
  for (const word of words) {
    const matchingModel = models.find(model => 
      word.toLowerCase() === model.triggerWord.toLowerCase()
    );
    
    if (matchingModel) {
      if (currentText) {
        segments.push({ text: currentText.trim(), type: 'text' });
        currentText = '';
      }
      segments.push({ 
        text: matchingModel.triggerWord,
        type: 'lora',
        loraId: matchingModel.id
      });
    } else {
      currentText += (currentText ? ' ' : '') + word;
    }
  }
  
  if (currentText) {
    segments.push({ text: currentText.trim(), type: 'text' });
  }
  
  return segments;
}

export function getPromptWithTriggerWords(basePrompt: string, models: LoraModel[]): string {
  const activeModels = models.filter(model => model.value > 0);
  if (activeModels.length === 0) return basePrompt.trim();

  const segments = parsePromptSegments(basePrompt, models);
  const existingTriggerWords = new Set(
    segments
      .filter(s => s.type === 'lora')
      .map(s => s.text.toLowerCase())
  );

  const missingModels = activeModels.filter(
    model => !existingTriggerWords.has(model.triggerWord.toLowerCase())
  );

  let finalPrompt = segments
    .map(segment => segment.text)
    .join(' ')
    .trim();

  if (missingModels.length > 0) {
    const missingTriggerWords = missingModels.map(model => model.triggerWord);
    finalPrompt = `${finalPrompt} ${missingTriggerWords.join(' ')}`.trim();
  }

  return finalPrompt;
}