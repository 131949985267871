import React from 'react';
import { 
  MessageSquare, 
  Hash, 
  Clock, 
  Wand2, 
  Palette, 
  LayoutPanelTop,
  Copy,
  ArrowUpCircle,
  Download,
  Trash2
} from 'lucide-react';
import type { TaskStatus } from '../lib/types';

interface GalleryProps {
  generations: TaskStatus[];
}

export function Gallery({ generations }: GalleryProps) {
  if (generations.length === 0) {
    return (
      <div className="max-w-6xl mx-auto text-center py-16">
        <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">No creations yet</h2>
        <p className="text-gray-600 dark:text-gray-400">
          Your generated images will appear here. Start by creating your first image!
        </p>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto">
      <h2 className="text-2xl font-semibold mb-8 text-gray-900 dark:text-white">Creations</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {generations.map((generation) => {
          const activeBoosts = generation.loraModels?.filter(model => model.value > 0) || [];
          const activeStyles = Object.entries(generation.styles || {})
            .filter(([_, value]) => value !== 'No Style')
            .map(([key, value]) => ({ id: key, value }));

          return (
            <div
              key={generation.task_id}
              className="bg-white dark:bg-rmz-gray rounded-xl overflow-hidden border border-gray-200 dark:border-rmz-gray-light transition-colors"
            >
              {generation.image_urls[0] && (
                <div className="aspect-square">
                  <img
                    src={generation.image_urls[0]}
                    alt={generation.prompt}
                    className="w-full h-full object-cover"
                  />
                </div>
              )}
              
              {/* Action Buttons */}
              <div className="grid grid-cols-4 gap-1 p-2 border-b border-gray-200 dark:border-rmz-gray-light">
                <button
                  className="flex items-center justify-center gap-1 px-2 py-1.5 text-xs font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-rmz-gray-light rounded-lg transition-colors"
                  title="Copy Prompt"
                >
                  <Copy className="w-4 h-4" />
                </button>
                <button
                  className="flex items-center justify-center gap-1 px-2 py-1.5 text-xs font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-rmz-gray-light rounded-lg transition-colors"
                  title="Upscale Image"
                >
                  <ArrowUpCircle className="w-4 h-4" />
                </button>
                <button
                  className="flex items-center justify-center gap-1 px-2 py-1.5 text-xs font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-rmz-gray-light rounded-lg transition-colors"
                  title="Download Image"
                >
                  <Download className="w-4 h-4" />
                </button>
                <button
                  className="flex items-center justify-center gap-1 px-2 py-1.5 text-xs font-medium text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/20 rounded-lg transition-colors"
                  title="Delete Image"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>

              <div className="p-4 space-y-4">
                {/* Prompt */}
                <div className="flex items-start gap-2">
                  <MessageSquare className="w-4 h-4 mt-1 flex-shrink-0 text-gray-500 dark:text-gray-400" />
                  <p className="text-sm text-gray-700 dark:text-gray-300 line-clamp-3">
                    {generation.prompt}
                  </p>
                </div>

                {/* Metadata Grid */}
                <div className="grid grid-cols-2 gap-2 text-xs">
                  {/* Seed */}
                  {generation.seed && (
                    <div className="flex items-center gap-1 text-gray-600 dark:text-gray-400">
                      <Hash className="w-3.5 h-3.5" />
                      <span>Seed: {generation.seed}</span>
                    </div>
                  )}
                  
                  {/* Generation Time */}
                  {generation.execution_time && (
                    <div className="flex items-center gap-1 text-gray-600 dark:text-gray-400">
                      <Clock className="w-3.5 h-3.5" />
                      <span>{generation.execution_time}s</span>
                    </div>
                  )}

                  {/* Aspect Ratio */}
                  {generation.aspectRatio && (
                    <div className="flex items-center gap-1 text-gray-600 dark:text-gray-400">
                      <LayoutPanelTop className="w-3.5 h-3.5" />
                      <span>{generation.aspectRatio}</span>
                    </div>
                  )}
                </div>

                {/* Active Boosts */}
                {activeBoosts.length > 0 && (
                  <div className="space-y-2">
                    <div className="flex items-center gap-1 text-xs font-medium text-gray-700 dark:text-gray-300">
                      <Wand2 className="w-3.5 h-3.5" />
                      <span>Active Boosts</span>
                    </div>
                    <div className="grid grid-cols-2 gap-1">
                      {activeBoosts.map(boost => (
                        <div 
                          key={boost.id}
                          className="text-xs bg-rmz-primary/10 text-rmz-primary rounded-lg px-2 py-1 flex justify-between items-center"
                        >
                          <span className="truncate">{boost.title}</span>
                          <span className="ml-1 flex-shrink-0">{boost.value.toFixed(1)}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Active Styles */}
                {activeStyles.length > 0 && (
                  <div className="space-y-2">
                    <div className="flex items-center gap-1 text-xs font-medium text-gray-700 dark:text-gray-300">
                      <Palette className="w-3.5 h-3.5" />
                      <span>Active Styles</span>
                    </div>
                    <div className="grid grid-cols-2 gap-1">
                      {activeStyles.map(style => (
                        <div 
                          key={style.id}
                          className="text-xs bg-rmz-primary/10 text-rmz-primary rounded-lg px-2 py-1 text-center truncate"
                        >
                          {style.value}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}