import React, { useState } from 'react';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useNavigate } from 'react-router-dom';
import { Sparkles, Loader2 } from 'lucide-react';
import { rmzLogo } from '../lib/assets/logo';

const EXPERIENCE_LEVELS = [
  'Beginner',
  'Intermediate',
  'Advanced',
  'Professional'
];

const VOLUME_RANGES = [
  '0-100 images/month',
  '100-500 images/month',
  '500-1000 images/month',
  '1000+ images/month'
];

const AREAS_OF_INTEREST = [
  'Art Generation',
  'Marketing Content',
  'Product Design',
  'Video Generation',
  'Business Applications',
  'Research & Development'
];

export function BetaContactPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    occupation: '',
    companyName: '',
    experienceLevel: '',
    toolsUsed: '',
    volumeRange: '',
    useCase: '',
    areasOfInterest: [] as string[],
    agreeToUpdates: false
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      await addDoc(collection(db, 'beta-contacts'), {
        ...formData,
        createdAt: serverTimestamp()
      });

      setSuccess(true);
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (err) {
      console.error('Error submitting form:', err);
      setError('Failed to submit application. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleAreaToggle = (area: string) => {
    setFormData(prev => ({
      ...prev,
      areasOfInterest: prev.areasOfInterest.includes(area)
        ? prev.areasOfInterest.filter(a => a !== area)
        : [...prev.areasOfInterest, area]
    }));
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#1a0b2e] via-[#1a0b2e] to-[#0f1955] text-white">
      {/* Gradient Orbs */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 left-0 w-[300px] h-[300px] bg-[#FF3B8B] rounded-full filter blur-[150px] opacity-20" />
        <div className="absolute top-0 right-0 w-[300px] h-[300px] bg-[#00F5D4] rounded-full filter blur-[150px] opacity-20" />
        <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-[300px] h-[300px] bg-[#B86EFF] rounded-full filter blur-[150px] opacity-20" />
      </div>

      <div className="relative container mx-auto px-4 py-8">
        <img src={rmzLogo} alt="RMZ.AI" className="h-16 w-auto mb-12" />
        
        <div className="max-w-2xl mx-auto">
          <div className="flex items-center gap-4 mb-8">
            <Sparkles className="w-8 h-8 text-[#00F5D4]" />
            <div>
              <h1 className="text-2xl font-bold bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] text-transparent bg-clip-text">Join Our Beta Program</h1>
              <p className="text-gray-400">Be among the first to experience our AI image generation platform</p>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">Full Name</label>
                <input
                  type="text"
                  required
                  value={formData.fullName}
                  onChange={e => setFormData(prev => ({ ...prev, fullName: e.target.value }))}
                  className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00F5D4] text-white"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">Email Address</label>
                <input
                  type="email"
                  required
                  value={formData.email}
                  onChange={e => setFormData(prev => ({ ...prev, email: e.target.value }))}
                  className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00F5D4] text-white"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">Occupation</label>
                <input
                  type="text"
                  required
                  value={formData.occupation}
                  onChange={e => setFormData(prev => ({ ...prev, occupation: e.target.value }))}
                  className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00F5D4] text-white"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">Company/Organization Name</label>
                <input
                  type="text"
                  required
                  value={formData.companyName}
                  onChange={e => setFormData(prev => ({ ...prev, companyName: e.target.value }))}
                  className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00F5D4] text-white"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">Experience with AI Image Generation</label>
                <select
                  required
                  value={formData.experienceLevel}
                  onChange={e => setFormData(prev => ({ ...prev, experienceLevel: e.target.value }))}
                  className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00F5D4] text-white"
                >
                  <option value="" className="bg-[#1a0b2e] text-white">Select your experience level</option>
                  {EXPERIENCE_LEVELS.map(level => (
                    <option key={level} value={level} className="bg-[#1a0b2e] text-white">{level}</option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">Which AI image generation tools have you used before?</label>
                <input
                  type="text"
                  placeholder="e.g., Midjourney, DALL-E, Stable Diffusion"
                  value={formData.toolsUsed}
                  onChange={e => setFormData(prev => ({ ...prev, toolsUsed: e.target.value }))}
                  className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00F5D4] text-white placeholder-gray-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">Estimated Monthly Image Generation Volume</label>
                <select
                  required
                  value={formData.volumeRange}
                  onChange={e => setFormData(prev => ({ ...prev, volumeRange: e.target.value }))}
                  className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00F5D4] text-white"
                >
                  <option value="" className="bg-[#1a0b2e] text-white">Select volume range</option>
                  {VOLUME_RANGES.map(range => (
                    <option key={range} value={range} className="bg-[#1a0b2e] text-white">{range}</option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">How do you plan to use our platform?</label>
                <textarea
                  required
                  value={formData.useCase}
                  onChange={e => setFormData(prev => ({ ...prev, useCase: e.target.value }))}
                  rows={4}
                  className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00F5D4] text-white placeholder-gray-500 resize-none"
                  placeholder="Please describe your intended use cases"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-3">Areas of Interest (Select all that apply)</label>
                <div className="grid grid-cols-2 gap-3">
                  {AREAS_OF_INTEREST.map(area => (
                    <label key={area} className="flex items-center gap-2 cursor-pointer">
                      <input
                        type="checkbox"
                        checked={formData.areasOfInterest.includes(area)}
                        onChange={() => handleAreaToggle(area)}
                        className="w-4 h-4 rounded border-gray-300 text-[#B86EFF] focus:ring-[#B86EFF]"
                      />
                      <span className="text-sm text-gray-300">{area}</span>
                    </label>
                  ))}
                </div>
              </div>

              <label className="flex items-start gap-2 cursor-pointer">
                <input
                  type="checkbox"
                  required
                  checked={formData.agreeToUpdates}
                  onChange={e => setFormData(prev => ({ ...prev, agreeToUpdates: e.target.checked }))}
                  className="mt-1 w-4 h-4 rounded border-gray-300 text-[#B86EFF] focus:ring-[#B86EFF]"
                />
                <span className="text-sm text-gray-300">
                  I agree to receive updates about the beta program and platform features
                </span>
              </label>
            </div>

            {error && (
              <div className="text-red-400 text-sm">{error}</div>
            )}

            {success && (
              <div className="text-green-400 text-sm">Application submitted successfully! Redirecting...</div>
            )}

            <button
              type="submit"
              disabled={loading || success}
              className="w-full px-8 py-3 bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] rounded-full text-white font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
            >
              {loading ? (
                <>
                  <Loader2 className="w-5 h-5 animate-spin" />
                  Submitting...
                </>
              ) : success ? (
                'Submitted!'
              ) : (
                'Submit Application'
              )}
            </button>

            <p className="text-sm text-gray-400 text-center">
              Your information will be kept confidential and used only for beta program purposes.
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}