import { useState, useEffect } from 'react';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

export function useGeneratedImages(count: number = 6) {
  const [images, setImages] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchImages() {
      try {
        const generationsRef = collection(db, 'generations');
        const q = query(
          generationsRef,
          orderBy('createdAt', 'desc'),
          limit(count * 2) // Fetch more to ensure we have enough valid URLs
        );

        const snapshot = await getDocs(q);
        const imageUrls = snapshot.docs
          .map(doc => {
            const data = doc.data();
            return data.firebaseImageUrls?.[0] || data.imageUrls?.[0];
          })
          .filter(Boolean)
          .slice(0, count);

        setImages(imageUrls);
      } catch (error) {
        console.error('Error fetching generated images:', error);
        setImages([]);
      } finally {
        setLoading(false);
      }
    }

    fetchImages();
  }, [count]);

  return { images, loading };
}