export const ASPECT_RATIOS = {
  SQUARE: '1:1',
  WIDE: '16:9',
  VERTICAL: '9:16',
} as const;

export type AspectRatio = typeof ASPECT_RATIOS[keyof typeof ASPECT_RATIOS];

export const ASPECT_RATIO_DIMENSIONS: Record<AspectRatio, { width: number; height: number }> = {
  [ASPECT_RATIOS.SQUARE]: { width: 1024, height: 1024 },
  [ASPECT_RATIOS.WIDE]: { width: 1216, height: 832 },
  [ASPECT_RATIOS.VERTICAL]: { width: 832, height: 1216 },
};