// List of trigger words that should be preserved
const TRIGGER_WORDS = [
  '3APF16',  // Almarai Product
  '1NAF18',  // Najdi Architecture
  '3SMF47',  // Saudi
  '1OMG64',  // Omani
  '1TOAF31'  // Traditional Omani Architecture
];

interface ExtractResult {
  cleanPrompt: string;
  triggerWords: Array<{
    word: string;
    index: number;
  }>;
}

export function extractTriggerWords(prompt: string): ExtractResult {
  const words = prompt.split(/\s+/);
  const triggerWords: Array<{ word: string; index: number }> = [];
  const cleanWords: string[] = [];
  
  words.forEach((word, index) => {
    if (TRIGGER_WORDS.includes(word)) {
      triggerWords.push({ word, index });
    } else {
      cleanWords.push(word);
    }
  });

  return {
    cleanPrompt: cleanWords.join(' '),
    triggerWords
  };
}

export function reinsertTriggerWords(enhancedPrompt: string, triggerWords: Array<{ word: string; index: number }>): string {
  if (triggerWords.length === 0) return enhancedPrompt;

  // Add trigger words at the end if they were present in the original
  return `${enhancedPrompt} ${triggerWords.map(tw => tw.word).join(' ')}`.trim();
}