// src/lib/api/instasd/service.ts
import { API_CONFIG } from './config';
import type { GenerationParams, GenerationResponse } from '../../types';
import { getPromptWithTriggerWords } from '../../utils/prompt-utils';
import { ASPECT_RATIO_DIMENSIONS } from '../../constants';
import { validateStyle } from '../../constants/styles';

export async function generateImage(params: GenerationParams): Promise<GenerationResponse> {
  try {
    const processedPrompt = getPromptWithTriggerWords(params.prompt, params.loraModels);
    const dimensions = ASPECT_RATIO_DIMENSIONS[params.aspectRatio];

    const response = await fetch(`${API_CONFIG.BASE_URL}/run_task`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${API_CONFIG.API_KEY}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        inputs: {
          [API_CONFIG.INPUT_IDS.SEED]: { 
            title: "Seed", 
            value: params.seed 
          },
          [API_CONFIG.INPUT_IDS.PROMPT]: { 
            title: "Prompt", 
            value: processedPrompt 
          },
          [API_CONFIG.INPUT_IDS.ALMARAI]: { 
            title: "Almarai Product Model strength",
            value: params.loraModels.find(m => m.id === 'almarai')?.value || 0 
          },
          [API_CONFIG.INPUT_IDS.NAJDI]: { 
            title: "Najdi Architecture strength",
            value: params.loraModels.find(m => m.id === 'najdi')?.value || 0 
          },
          [API_CONFIG.INPUT_IDS.SAUDI]: { 
            title: "Saudi Model strength",
            value: params.loraModels.find(m => m.id === 'saudi')?.value || 0 
          },
          [API_CONFIG.INPUT_IDS.OMANI]: { 
            title: "Omani Model strength",
            value: params.loraModels.find(m => m.id === 'omani')?.value || 0 
          },
          [API_CONFIG.INPUT_IDS.OMANI_ARCH]: { 
            title: "Traditional Omani Architecture Strength",
            value: params.loraModels.find(m => m.id === 'omani-arch')?.value || 0 
          },
          [API_CONFIG.INPUT_IDS.WIDTH]: { 
            title: "Width", 
            value: dimensions.width 
          },
          [API_CONFIG.INPUT_IDS.HEIGHT]: { 
            title: "Height", 
            value: dimensions.height 
          },
          [API_CONFIG.INPUT_IDS.STYLE_1]: { 
            title: "Style 1", 
            value: validateStyle(params.styles?.style1)
          },
          [API_CONFIG.INPUT_IDS.STYLE_2]: { 
            title: "Style 2", 
            value: validateStyle(params.styles?.style2)
          },
          [API_CONFIG.INPUT_IDS.STYLE_3]: { 
            title: "Style 3", 
            value: validateStyle(params.styles?.style3)
          },
          [API_CONFIG.INPUT_IDS.STYLE_4]: { 
            title: "Style 4", 
            value: validateStyle(params.styles?.style4)
          }
        }
      })
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw new Error(errorData?.message || `API error: ${response.status}`);
    }

    const data = await response.json();
    
    if (!data.task_id) {
      throw new Error('Invalid response: missing task ID');
    }

    return {
      task_id: data.task_id,
      status: data.status || 'IN_QUEUE',
      image_urls: [],
      completed_steps: 0,
      estimated_steps: data.estimated_steps || 20,
      prompt: processedPrompt,
      seed: params.seed,
      styles: params.styles
    };
  } catch (error) {
    console.error('Generation error:', error);
    throw error instanceof Error ? error : new Error('Failed to generate image');
  }
}

export async function getTaskStatus(taskId: string): Promise<GenerationResponse> {
  try {
    const response = await fetch(`${API_CONFIG.BASE_URL}/task_status/${taskId}`, {
      headers: {
        'Authorization': `Bearer ${API_CONFIG.API_KEY}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw new Error(errorData?.message || `API error: ${response.status}`);
    }

    const data = await response.json();
    
    let status = data.status || 'IN_QUEUE';
    if (status === 'CREATED') status = 'IN_QUEUE';
    if (data.image_urls?.length > 0) status = 'COMPLETED';

    // Calculate progress and set appropriate message
    const progress = data.completed_steps ? 
      Math.round((data.completed_steps / (data.estimated_steps || 20)) * 100) : 0;

    let progressMessage = 'Generating';
    if (progress >= 80 && progress < 90) {
      progressMessage = 'Enhancing image';
    } else if (progress >= 90) {
      progressMessage = 'Finalizing';
    }

    return {
      task_id: taskId,
      status,
      image_urls: data.image_urls || [],
      completed_steps: data.completed_steps || 0,
      estimated_steps: data.estimated_steps || 20,
      error: data.error,
      delay_time: data.delay_time,
      execution_time: data.execution_time,
      cost: data.cost,
      seed: data.seed,
      prompt: data.prompt,
      styles: data.styles,
      progress_message: progressMessage
    };
  } catch (error) {
    console.error('Status check error:', error);
    throw error instanceof Error ? error : new Error('Failed to check generation status');
  }
}
