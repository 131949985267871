import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Sparkles, Zap, Users, MessageCircle, Rocket } from 'lucide-react';
import { rmzLogo } from '../lib/assets/logo';

const BETA_FEATURES = [
  {
    icon: Sparkles,
    title: 'Early Access',
    description: 'Get first access to culturally-aware image generation and brand-aligned features'
  },
  {
    icon: Users,
    title: 'Exclusive Community',
    description: 'Join a community of creators pushing the boundaries of visual expression'
  },
  {
    icon: MessageCircle,
    title: 'Direct Impact',
    description: 'Shape the future of rmz.ai with your valuable feedback and insights'
  },
  {
    icon: Rocket,
    title: 'Launch Benefits',
    description: 'Enjoy exclusive benefits and special offers at official launch'
  }
];

const PROGRAM_BENEFITS = [
  'Early access to all new features, including culturally-aware image generation',
  'Free credits for generating authentic and brand-aligned content',
  'Shape the future of rmz.ai with your valuable feedback',
  'Exclusive benefits and offers at the official launch'
];

export function BetaPage() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#1a0b2e] via-[#1a0b2e] to-[#0f1955] text-white">
      {/* Gradient Orbs */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 left-0 w-[300px] h-[300px] bg-[#FF3B8B] rounded-full filter blur-[150px] opacity-20" />
        <div className="absolute top-0 right-0 w-[300px] h-[300px] bg-[#00F5D4] rounded-full filter blur-[150px] opacity-20" />
        <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-[300px] h-[300px] bg-[#B86EFF] rounded-full filter blur-[150px] opacity-20" />
      </div>

      {/* Header */}
      <header className="relative container mx-auto px-4 py-6">
        <img src={rmzLogo} alt="RMZ.AI" className="h-16 w-auto" />
      </header>

      {/* Hero Section */}
      <div className="relative container mx-auto px-4 py-16 text-center">
        <Sparkles className="w-16 h-16 mx-auto mb-8 text-[#00F5D4]" />
        <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] text-transparent bg-clip-text">
          Unlock Authentic & Brand-Aligned Creativity with AI
        </h1>
        <p className="text-xl text-gray-300 mb-8 max-w-3xl mx-auto">
          Revolutionize your content creation with rmz.ai. Generate culturally relevant visuals 
          that resonate with your audience and align perfectly with your brand identity.
        </p>
        <button
          onClick={() => navigate('/beta-contact')}
          className="px-8 py-3 bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] rounded-full text-lg font-medium hover:opacity-90 transition-opacity"
        >
          Register for Beta Program
        </button>
      </div>

      {/* Features Grid */}
      <div className="relative container mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {BETA_FEATURES.map((feature, index) => (
            <div
              key={index}
              className="bg-white/5 backdrop-blur-lg rounded-2xl p-6 border border-white/10 hover:border-[#00F5D4]/30 transition-colors"
            >
              <feature.icon className="w-10 h-10 text-[#00F5D4] mb-4" />
              <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
              <p className="text-gray-300">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Program Benefits */}
      <div className="relative container mx-auto px-4 py-16">
        <div className="bg-white/5 backdrop-blur-lg rounded-3xl p-8 border border-white/10">
          <div className="flex items-center gap-4 mb-8">
            <Zap className="w-8 h-8 text-[#FF3B8B]" />
            <h2 className="text-2xl font-bold">Beta Program Benefits</h2>
          </div>
          <div className="grid gap-4">
            {PROGRAM_BENEFITS.map((benefit, index) => (
              <div
                key={index}
                className="flex items-center gap-4 bg-white/5 rounded-xl p-4 border border-white/5 hover:border-[#B86EFF]/30 transition-colors"
              >
                <div className="w-2 h-2 rounded-full bg-[#B86EFF]" />
                <p className="text-gray-200">{benefit}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="relative container mx-auto px-4 py-16 text-center">
        <h2 className="text-3xl font-bold mb-6 bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] text-transparent bg-clip-text">
          Be a pioneer in the new era of culturally authentic and brand-aligned content creation
        </h2>
        <p className="text-gray-300 mb-8 max-w-2xl mx-auto">
          Join the rmz.ai beta program today and transform your creative process with AI-powered tools 
          that understand your cultural context and brand values.
        </p>
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <button
            onClick={() => navigate('/beta-contact')}
            className="px-8 py-3 bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] rounded-full text-lg font-medium hover:opacity-90 transition-opacity"
          >
            Join Beta Program
          </button>
          <button
            onClick={() => navigate('/')}
            className="px-8 py-3 border border-white/20 rounded-full text-lg font-medium hover:bg-white/5 transition-colors"
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
}