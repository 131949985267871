import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup
} from 'firebase/auth';
import { auth } from './config';
import { createUserState } from './db';

const googleProvider = new GoogleAuthProvider();

export const signUp = async (email: string, password: string) => {
  try {
    const result = await createUserWithEmailAndPassword(auth, email, password);
    // Create user state record
    await createUserState(result.user.uid, result.user.email || '');
    return result.user;
  } catch (error: any) {
    let message = 'Failed to create account';
    if (error.code === 'auth/email-already-in-use') {
      message = 'This email is already registered. Please sign in instead.';
    } else if (error.code === 'auth/weak-password') {
      message = 'Password should be at least 6 characters long.';
    } else if (error.code === 'auth/invalid-email') {
      message = 'Please enter a valid email address.';
    }
    throw new Error(message);
  }
};

export const signIn = async (email: string, password: string) => {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    return result.user;
  } catch (error: any) {
    let message = 'Failed to sign in';
    if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
      message = 'Invalid email or password.';
    } else if (error.code === 'auth/too-many-requests') {
      message = 'Too many failed attempts. Please try again later.';
    }
    throw new Error(message);
  }
};

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    // Create user state record for Google sign-in
    await createUserState(result.user.uid, result.user.email || '');
    return result.user;
  } catch (error: any) {
    let message = 'Failed to sign in with Google';
    if (error.code === 'auth/popup-blocked') {
      message = 'Please allow popups for this website to sign in with Google.';
    }
    throw new Error(message);
  }
};