import { z } from 'zod';

export const API_CONFIG = {
  BASE_URL: 'https://api.instasd.com/api_endpoints/w5bqtptandg2m9',
  API_KEY: 'aK0u39gTDd9GgkME3B8asA',
  INPUT_IDS: {
    SEED: 'cc59d44d02e5364b',
    PROMPT: 'c203c342a9921102',
    ALMARAI: 'a3a8bce14faf3b58',
    NAJDI: 'f5c175099429bef8',
    SAUDI: '164e2fe5999d6450',
    OMANI: '196e3c3cff5ac13f',
    OMANI_ARCH: '4a20704e3bd87306',
    WIDTH: 'ea116395db2a8924',
    HEIGHT: 'dedafc77629ac14d',
    STYLE_1: 'feeeb9447503fe95',
    STYLE_2: '9b170573913011d4',
    STYLE_3: 'ee3c529f22c41589',
    STYLE_4: '3404d8771aed0817'
  },
  RETRY: {
    MAX_ATTEMPTS: 3,
    INITIAL_DELAY: 1000,
    BACKOFF_FACTOR: 2,
    MAX_DELAY: 5000
  }
} as const;

export const taskResponseSchema = z.object({
  task_id: z.string(),
  status: z.string(),
  estimated_steps: z.number().optional(),
  completed_steps: z.number().optional(),
  image_urls: z.array(z.string()).optional(),
  video_urls: z.array(z.string()).optional(),
  error: z.string().optional(),
  delay_time: z.number().optional(),
  execution_time: z.number().optional(),
  cost: z.number().optional(),
  seed: z.number().optional(),
  prompt: z.string().optional()
});

export type TaskResponse = z.infer<typeof taskResponseSchema>;