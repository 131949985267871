import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Sun, Moon, LogOut, Palette, ArrowUpCircle, Home } from 'lucide-react';
import { useTheme } from '../contexts/ThemeContext';
import { useAuth } from '../contexts/AuthContext';
import { rmzLogo } from '../lib/assets/logo';

interface HeaderProps {
  onShowGallery: () => void;
  onShowCreate: () => void;
}

export function Header({ onShowGallery, onShowCreate }: HeaderProps) {
  const { theme, toggleTheme } = useTheme();
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <header className="bg-white dark:bg-rmz-dark border-b border-gray-200 dark:border-rmz-gray transition-colors">
      <div className="container mx-auto px-4 h-16 flex items-center justify-between">
        <div className="flex items-center">
          <img 
            src={rmzLogo}
            alt="RMZ.AI" 
            className="h-16 w-auto"
            style={{ opacity: 0.9 }}
          />
        </div>
        <div className="flex items-center gap-4">
          <button
            onClick={() => navigate('/')}
            className="p-2 rounded-lg text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-rmz-gray transition-colors"
            aria-label="Home"
          >
            <Home className="w-5 h-5" />
          </button>
          <button
            onClick={toggleTheme}
            className="p-2 rounded-lg text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-rmz-gray transition-colors"
            aria-label={`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}
          >
            {theme === 'light' ? (
              <Moon className="w-5 h-5" />
            ) : (
              <Sun className="w-5 h-5" />
            )}
          </button>
          <button
            onClick={onShowCreate}
            className="px-4 py-2 rounded-lg bg-rmz-primary text-rmz-dark font-medium hover:opacity-90 transition-opacity"
          >
            Create
          </button>
          <button
            className="px-4 py-2 rounded-lg bg-gradient-primary text-rmz-dark font-medium hover:opacity-90 transition-opacity flex items-center gap-2"
          >
            <Palette className="w-5 h-5" />
            Canvas
          </button>
          <button
            className="px-4 py-2 rounded-lg bg-rmz-purple text-white font-medium hover:opacity-90 transition-opacity flex items-center gap-2"
          >
            <ArrowUpCircle className="w-5 h-5" />
            Upscale
          </button>
          <button
            onClick={onShowGallery}
            className="px-4 py-2 rounded-lg text-gray-900 dark:text-white font-medium hover:text-rmz-primary transition-colors"
          >
            Gallery
          </button>
          <button
            onClick={handleLogout}
            className="p-2 rounded-lg text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-rmz-gray transition-colors flex items-center gap-2"
            aria-label="Logout"
          >
            <LogOut className="w-5 h-5" />
          </button>
        </div>
      </div>
    </header>
  );
}