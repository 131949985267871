import { z } from 'zod';

export const STYLE_VALUES = {
  CARTOON_3D: '3D | Character > Cartoon',
  CINEMATIC: 'Photography | Cinematic',
  CARTOON: 'Illustration | Cartoon',
  PIXEL_ART: 'ART | Pixel Art',
  NONE: 'No Style'
} as const;

export const STYLE_OPTIONS = [
  { 
    id: 'style1', 
    title: 'Cartoon 3D', 
    value: STYLE_VALUES.CARTOON_3D,
    description: 'Stylized 3D cartoon look',
    image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai.firebasestorage.app/o/Rmz.ai_resources%2F3D%20style.PNG?alt=media&token=45e04a2a-a59f-4299-b3ef-b755b11a117e'
  },
  { 
    id: 'style2', 
    title: 'Cinematic', 
    value: STYLE_VALUES.CINEMATIC,
    description: 'Professional cinematic photography style',
    image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai.firebasestorage.app/o/Rmz.ai_resources%2FCinimatic%20style.png?alt=media&token=e8413935-18a1-4444-9189-e09fc9ffb67a'
  },
  { 
    id: 'style3', 
    title: 'Cartoon', 
    value: STYLE_VALUES.CARTOON,
    description: 'Stylized cartoon illustration style',
    image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai.firebasestorage.app/o/Rmz.ai_resources%2FCartoon%20Style.png?alt=media&token=7cda7c63-ec67-42e2-880d-9cecc783da54'
  },
  { 
    id: 'style4', 
    title: 'Pixel Art', 
    value: STYLE_VALUES.PIXEL_ART,
    description: 'Retro pixel art style',
    image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai.firebasestorage.app/o/Rmz.ai_resources%2FPixel%20art%20Style.png?alt=media&token=097ac88c-26f2-42e4-9191-f9a1b1d22ed6'
  }
] as const;

export const styleSchema = z.enum([
  '3D | Character > Cartoon',
  'Photography | Cinematic',
  'Illustration | Cartoon',
  'ART | Pixel Art',
  'No Style'
]);

export type StyleValue = z.infer<typeof styleSchema>;

export function validateStyle(style: unknown): StyleValue {
  try {
    return styleSchema.parse(style);
  } catch {
    return STYLE_VALUES.NONE;
  }
}