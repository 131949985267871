import React from 'react';
import { useNavigate } from 'react-router-dom';
import { rmzLogo } from '../lib/assets/logo';
import { Clock, ArrowLeft } from 'lucide-react';

export function PendingApprovalPage() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#1a0b2e] via-[#1a0b2e] to-[#0f1955] text-white">
      {/* Gradient Orbs */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 left-0 w-[300px] h-[300px] bg-[#FF3B8B] rounded-full filter blur-[150px] opacity-20" />
        <div className="absolute top-0 right-0 w-[300px] h-[300px] bg-[#00F5D4] rounded-full filter blur-[150px] opacity-20" />
        <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-[300px] h-[300px] bg-[#B86EFF] rounded-full filter blur-[150px] opacity-20" />
      </div>

      <div className="relative container mx-auto px-4 py-8">
        <img src={rmzLogo} alt="RMZ.AI" className="h-16 w-auto mb-16" />
        
        <div className="max-w-2xl mx-auto text-center">
          <div className="bg-white/5 backdrop-blur-lg rounded-3xl p-12 border border-white/10">
            <Clock className="w-16 h-16 mx-auto mb-6 text-[#00F5D4]" />
            
            <h1 className="text-3xl font-bold mb-4 bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] text-transparent bg-clip-text">
              Approval Pending
            </h1>
            
            <p className="text-lg text-gray-300 mb-8">
              Your account is currently pending approval from our team. We'll review your application and get back to you soon.
            </p>

            <div className="space-y-4">
              <div className="bg-white/5 rounded-xl p-4">
                <p className="text-gray-300">
                  While you wait, you can explore our public gallery or learn more about our features.
                </p>
              </div>

              <button
                onClick={() => navigate('/')}
                className="flex items-center justify-center gap-2 w-full px-6 py-3 bg-white/10 hover:bg-white/15 rounded-xl transition-colors"
              >
                <ArrowLeft className="w-5 h-5" />
                Return to Home
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}