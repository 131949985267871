import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { LoginPage } from './components/LoginPage';
import { MainApp } from './components/MainApp';
import { HomePage } from './components/HomePage';
import { ContactPage } from './components/ContactPage';
import { PublicGallery } from './components/PublicGallery';
import { BetaPage } from './components/BetaPage';
import { BetaContactPage } from './components/BetaContactPage';
import { PendingApprovalPage } from './components/PendingApprovalPage';

function PrivateRoute({ children }: { children: React.ReactNode }) {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-rmz-darker flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-rmz-primary"></div>
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
}

function App() {
  return (
    <Router>
      <ThemeProvider>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/gallery" element={<PublicGallery />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/beta" element={<BetaPage />} />
            <Route path="/beta-contact" element={<BetaContactPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/pending-approval" element={<PendingApprovalPage />} />
            <Route
              path="/create"
              element={
                <PrivateRoute>
                  <MainApp />
                </PrivateRoute>
              }
            />
          </Routes>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;