import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics, isSupported } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyAM4ryOo2i-8r20hYS0UQK2bdKjcZtGLKw",
  authDomain: "rmz-ai.firebaseapp.com",
  projectId: "rmz-ai",
  storageBucket: "rmz-ai.firebasestorage.app",
  messagingSenderId: "619895337698",
  appId: "1:619895337698:web:8d4b6f327cece634de24bb",
  measurementId: "G-HXZXPCPCH7"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// Initialize analytics conditionally
let analytics = null;
isSupported().then(yes => {
  if (yes) {
    analytics = getAnalytics(app);
  }
});

export { analytics };

// Initialize Firestore indexes
const INDEXES = [
  {
    collectionGroup: 'generations',
    queryScope: 'COLLECTION',
    fields: [
      { fieldPath: 'userId', order: 'ASCENDING' },
      { fieldPath: 'createdAt', order: 'DESCENDING' }
    ]
  }
];