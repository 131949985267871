import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Heart, Sparkles, Filter } from 'lucide-react';
import { rmzLogo } from '../lib/assets/logo';

const CATEGORIES = [
  {
    title: 'Art Generation',
    image: 'https://images.unsplash.com/photo-1579783902614-a3fb3927b6a5?w=800&auto=format&fit=crop&q=80',
    description: 'Creative & artistic AI generations'
  },
  {
    title: 'Architecture',
    image: 'https://images.unsplash.com/photo-1616578738046-8d6bbb4ee28e?w=800&auto=format&fit=crop&q=80',
    description: 'Modern & traditional architectural designs'
  },
  {
    title: 'Interior Design',
    image: 'https://images.unsplash.com/photo-1618221195710-dd6b41faaea6?w=800&auto=format&fit=crop&q=80',
    description: 'Stunning interior spaces'
  },
  {
    title: 'Product',
    image: 'https://images.unsplash.com/photo-1583847268964-b28dc8f51f92?w=800&auto=format&fit=crop&q=80',
    description: 'Professional product photography'
  },
  {
    title: 'Advertising',
    image: 'https://images.unsplash.com/photo-1542744094-24638eff58bb?w=800&auto=format&fit=crop&q=80',
    description: 'Creative marketing & advertising'
  }
];

interface GeneratedImage {
  id: string;
  imageUrl: string;
  prompt: string;
  createdAt: any;
  userId: string;
  likes?: number;
}

export function PublicGallery() {
  const [images, setImages] = useState<GeneratedImage[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  useEffect(() => {
    async function fetchImages() {
      try {
        const generationsRef = collection(db, 'generations');
        const q = query(
          generationsRef,
          orderBy('createdAt', 'desc'),
          limit(50)
        );

        const snapshot = await getDocs(q);
        const fetchedImages = snapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            imageUrl: data.firebaseImageUrls?.[0] || data.imageUrls?.[0],
            prompt: data.prompt,
            createdAt: data.createdAt,
            userId: data.userId,
            likes: Math.floor(Math.random() * 30)
          };
        }).filter(img => img.imageUrl);

        setImages(fetchedImages);
      } catch (error) {
        console.error('Error fetching images:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchImages();
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#1a0b2e] via-[#1a0b2e] to-[#0f1955] text-white">
      {/* Gradient Orbs */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 left-0 w-[300px] h-[300px] bg-[#FF3B8B] rounded-full filter blur-[150px] opacity-20" />
        <div className="absolute top-0 right-0 w-[300px] h-[300px] bg-[#00F5D4] rounded-full filter blur-[150px] opacity-20" />
        <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-[300px] h-[300px] bg-[#B86EFF] rounded-full filter blur-[150px] opacity-20" />
      </div>

      {/* Header */}
      <div className="relative container mx-auto px-4 py-8">
        <div className="flex items-center justify-between mb-12">
          <img src={rmzLogo} alt="RMZ.AI" className="h-16 w-auto" />
          <div className="flex items-center gap-4">
            <button className="px-4 py-2 rounded-full bg-white/5 border border-white/10 hover:border-[#00F5D4]/30 transition-colors flex items-center gap-2">
              <Filter className="w-4 h-4" />
              <span>Filter</span>
            </button>
          </div>
        </div>

        {/* Categories */}
        <div className="flex gap-6 overflow-x-auto pb-6 scrollbar-hide">
          {CATEGORIES.map((category, index) => (
            <button
              key={index}
              onClick={() => setSelectedCategory(category.title)}
              className={`flex-none relative group rounded-2xl overflow-hidden transition-all duration-300 hover:scale-105 ${
                selectedCategory === category.title ? 'ring-2 ring-[#00F5D4]' : ''
              }`}
              style={{ width: '280px', height: '160px' }}
            >
              <img
                src={category.image}
                alt={category.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent group-hover:via-black/30 transition-all duration-300">
                <div className="absolute bottom-0 left-0 right-0 p-4">
                  <h3 className="text-lg font-medium mb-1">{category.title}</h3>
                  <p className="text-sm text-gray-300 opacity-0 group-hover:opacity-100 transform translate-y-2 group-hover:translate-y-0 transition-all duration-300">
                    {category.description}
                  </p>
                </div>
              </div>
            </button>
          ))}
        </div>
      </div>

      {/* Gallery Grid */}
      <div className="relative container mx-auto px-4 py-8">
        {loading ? (
          <div className="flex flex-col items-center justify-center h-64 gap-4">
            <Sparkles className="w-8 h-8 text-[#00F5D4] animate-pulse" />
            <p className="text-gray-400">Loading amazing creations...</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {images.map((image) => (
              <div
                key={image.id}
                className="group relative rounded-2xl overflow-hidden bg-white/5 border border-white/10 hover:border-[#00F5D4]/30 transition-all duration-300"
              >
                <img
                  src={image.imageUrl}
                  alt={image.prompt}
                  className="w-full aspect-square object-cover transform group-hover:scale-105 transition-transform duration-300"
                  loading="lazy"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <div className="absolute bottom-0 left-0 right-0 p-4">
                    <p className="text-sm text-white/90 line-clamp-2 mb-2">
                      {image.prompt}
                    </p>
                    <div className="flex items-center gap-2">
                      <Heart className="w-4 h-4 text-[#FF3B8B]" />
                      <span className="text-sm text-white/70">{image.likes}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}