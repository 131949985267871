import { GoogleGenerativeAI } from '@google/generative-ai';
import type { EnhancementRequest, EnhancementResponse } from '../types/enhancement';
import { extractTriggerWords, reinsertTriggerWords } from '../utils/trigger-words';

const API_KEY = import.meta.env.VITE_GOOGLE_AI_API_KEY;

export async function enhanceWithGemini(request: EnhancementRequest): Promise<EnhancementResponse> {
  try {
    const genAI = new GoogleGenerativeAI(API_KEY);
    const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

    // Extract trigger words before enhancement
    const { cleanPrompt, triggerWords } = extractTriggerWords(request.prompt);

    const prompt = `You are an expert at enhancing image generation prompts. Transform this prompt into a detailed and structured description following these guidelines:

Core Concept:
Create a [quality descriptor] [medium] inspired by [subject/theme].

Essential Components to consider and include:

1. Visual Elements:
- Primary Subject: Core features, defining elements, scale
- Material Details: Textures, surface qualities
- Color Palette: Primary colors, secondary accents, tonal ranges

2. Artistic Direction:
- Style Reference: Artistic movement/influence
- Composition: Subject positioning, viewing perspective

3. Atmospheric Elements:
- Environmental Setting: Time period, time of day, weather
- Mood and Theme: Emotional tone, cultural context

4. Technical Specifications:
- Lighting Design: Light sources, shadows, contrast
- Focus and Depth: Focal points, detail hierarchy

Original prompt: "${cleanPrompt}"

Enhance this prompt maintaining its core intent while adding structured detail. Format the response as a single, flowing description without headers or bullet points.`;

    const result = await model.generateContent(prompt);
    const enhancedPrompt = result.response.text().trim();

    // Reinsert trigger words in their original positions
    const finalPrompt = reinsertTriggerWords(enhancedPrompt, triggerWords);

    return {
      enhancedPrompt: finalPrompt,
      suggestions: []
    };
  } catch (error) {
    console.error('Gemini API call failed:', error);
    throw new Error('Failed to enhance prompt with Gemini AI');
  }
}