import React, { useState } from 'react';
import { Check } from 'lucide-react';
import { Slider } from './Slider';
import type { LoraModel } from '../types';

interface LoraCategoryProps {
  title: string;
  models: LoraModel[];
  onChange: (models: LoraModel[]) => void;
}

export function LoraCategory({ title, models, onChange }: LoraCategoryProps) {
  const [imageErrors, setImageErrors] = useState<Record<string, boolean>>({});

  const toggleModel = (id: string) => {
    const updatedModels = models.map(model => 
      model.id === id ? { ...model, value: model.value === 0 ? 0.5 : 0 } : model
    );
    onChange(updatedModels);
  };

  const updateModelStrength = (id: string, value: number) => {
    const updatedModels = models.map(model =>
      model.id === id ? { ...model, value } : model
    );
    onChange(updatedModels);
  };

  const getImageForModel = (id: string) => {
    switch (id) {
      case 'almarai':
        return 'https://saudipedia.com/en/saudipediaen/uploads/images/2024/07/04/93634.jpg';
      case 'najdi':
        return 'https://www.saudiarabiavisa.net/images/Saudi_Arabia_Visa_Services_Ushaiger_Heritage_Village.jpg';
      case 'saudi':
        return 'https://tessutiitaliano.com/wp-content/uploads/2020/08/What-are-the-Saudi-traditional-dress-.jpg';
      case 'omani':
        return 'https://firebasestorage.googleapis.com/v0/b/beyond-22c2f.firebasestorage.app/o/App%20base%20Photos%2FOmani_model.png?alt=media&token=4f06712b-0e80-49f3-a4e5-6d1a0d164dde';
      case 'omani-arch':
        return 'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/97409258750761.5a085639b3d56.jpg';
      default:
        return '';
    }
  };

  const handleImageError = (modelId: string) => {
    setImageErrors(prev => ({ ...prev, [modelId]: true }));
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium text-gray-900 dark:text-white">{title}</h3>
      <div className="space-y-4">
        {models.map(model => (
          <div key={model.id} className="flex gap-4">
            <button
              onClick={() => toggleModel(model.id)}
              className={`relative rounded-xl overflow-hidden w-24 h-24 flex-shrink-0 ${
                model.value > 0 ? 'ring-2 ring-rmz-primary' : 'ring-1 ring-gray-200 dark:ring-rmz-gray-light'
              }`}
            >
              <div className="absolute inset-0 bg-gray-100 dark:bg-rmz-gray animate-pulse" />
              <img
                src={getImageForModel(model.id)}
                alt={model.title}
                className={`relative w-full h-full object-cover transition-all duration-300 ${
                  model.value === 0 ? 'opacity-50' : 'opacity-100'
                }`}
                loading="lazy"
                onError={() => handleImageError(model.id)}
              />
              {model.value > 0 && (
                <div className="absolute top-2 right-2">
                  <div className="bg-rmz-primary rounded-full p-1">
                    <Check className="w-4 h-4 text-rmz-dark" />
                  </div>
                </div>
              )}
            </button>
            
            <div className="flex-1 space-y-2">
              <div className="flex justify-between items-center">
                <div>
                  <h4 className="text-sm font-medium text-gray-900 dark:text-white">{model.title}</h4>
                  <p className="text-xs text-gray-500 dark:text-gray-400">Boost Code: {model.triggerWord}</p>
                </div>
                <span className="text-sm text-gray-700 dark:text-gray-300">
                  {model.value.toFixed(1)}
                </span>
              </div>
              
              <Slider
                value={model.value}
                onChange={(value) => updateModelStrength(model.id, value)}
                disabled={model.value === 0}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}