import React from 'react';
import { Check } from 'lucide-react';
import type { StyleOptions } from '../lib/types';
import { STYLE_OPTIONS, STYLE_VALUES } from '../lib/constants/styles';

interface StyleSelectorProps {
  styles: StyleOptions;
  onChange: (styles: StyleOptions) => void;
}

export function StyleSelector({ styles, onChange }: StyleSelectorProps) {
  const toggleStyle = (styleId: keyof StyleOptions, styleValue: string) => {
    const currentValue = styles[styleId];
    const newValue = currentValue === styleValue ? STYLE_VALUES.NONE : styleValue;
    
    onChange({
      ...styles,
      [styleId]: newValue
    });
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium text-gray-900 dark:text-white">Activate Magic</h3>
      <div className="grid grid-cols-2 gap-4">
        {STYLE_OPTIONS.map(style => {
          const isActive = styles[style.id as keyof StyleOptions] === style.value;
          
          return (
            <button
              key={style.id}
              onClick={() => toggleStyle(style.id as keyof StyleOptions, style.value)}
              className={`relative rounded-xl overflow-hidden aspect-square group ${
                isActive 
                  ? 'ring-2 ring-rmz-primary' 
                  : 'ring-1 ring-gray-200 dark:ring-rmz-gray-light hover:ring-rmz-primary/50'
              }`}
              title={style.description}
            >
              <img
                src={style.image}
                alt={style.title}
                className={`w-full h-full object-cover transition-opacity duration-300 ${
                  isActive ? 'opacity-100' : 'opacity-50'
                }`}
              />
              <div className={`absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-transparent p-4 flex flex-col justify-between transition-opacity ${
                isActive ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'
              }`}>
                <div className="self-end">
                  {isActive && (
                    <div className="bg-rmz-primary rounded-full p-1">
                      <Check className="w-4 h-4 text-rmz-dark" />
                    </div>
                  )}
                </div>
                <div>
                  <h4 className="text-sm font-medium text-white">
                    {style.title}
                  </h4>
                  <p className="text-xs text-gray-200">
                    {isActive ? 'Active' : 'Not active'}
                  </p>
                </div>
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
}