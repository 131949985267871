import React from 'react';

interface SliderProps {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
}

export function Slider({ 
  value, 
  onChange, 
  min = 0, 
  max = 1, 
  step = 0.1,
  disabled = false 
}: SliderProps) {
  return (
    <input
      type="range"
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={(e) => onChange(parseFloat(e.target.value))}
      className={`w-full h-1.5 bg-gray-200 dark:bg-rmz-gray-light rounded-lg appearance-none cursor-pointer accent-rmz-primary disabled:opacity-50 disabled:cursor-not-allowed transition-colors`}
      disabled={disabled}
    />
  );
}