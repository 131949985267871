export function throttle<T extends (...args: any[]) => Promise<any>>(
  fn: T,
  delay: number
): T {
  let lastRun = 0;
  let timeout: NodeJS.Timeout | null = null;

  return (async (...args: Parameters<T>): Promise<ReturnType<T>> => {
    const now = Date.now();

    if (lastRun && now < lastRun + delay) {
      if (timeout) {
        clearTimeout(timeout);
      }

      return new Promise((resolve) => {
        timeout = setTimeout(() => {
          lastRun = Date.now();
          resolve(fn(...args));
        }, delay);
      });
    }

    lastRun = now;
    return fn(...args);
  }) as T;
}