import React from 'react';
import { MessageSquare, Hash, Clock } from 'lucide-react';
import type { TaskStatus } from '../lib/types';

interface ImageHistoryProps {
  generations: TaskStatus[];
}

export function ImageHistory({ generations }: ImageHistoryProps) {
  if (generations.length === 0) {
    return (
      <div className="bg-white dark:bg-rmz-gray rounded-xl p-3 border border-gray-200 dark:border-rmz-gray-light text-center transition-colors">
        <p className="text-gray-500 dark:text-gray-400 text-xs">No generations yet</p>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-rmz-gray rounded-xl border border-gray-200 dark:border-rmz-gray-light overflow-hidden transition-colors">
      <div className="p-2 border-b border-gray-200 dark:border-rmz-gray-light">
        <h2 className="font-medium text-xs text-gray-900 dark:text-white">History</h2>
      </div>
      <div className="divide-y divide-gray-200 dark:divide-rmz-gray-light max-h-[800px] overflow-y-auto">
        {generations.map((generation, index) => {
          // Create a unique key using task_id and index
          const uniqueKey = `${generation.task_id}-${index}`;
          
          return (
            <div
              key={uniqueKey}
              className="p-2 hover:bg-gray-50 dark:hover:bg-rmz-gray-light/50 transition-colors"
            >
              {generation.image_urls[0] && (
                <div className="relative w-full h-0 pb-[100%] bg-gray-100 dark:bg-rmz-gray-light mb-1">
                  <div className="absolute inset-0 flex items-center justify-center">
                    <img
                      src={generation.image_urls[0]}
                      alt={generation.prompt}
                      className="max-w-full max-h-full w-auto h-auto object-contain"
                      loading="lazy"
                    />
                  </div>
                </div>
              )}
              <p className="text-xs text-gray-700 dark:text-gray-300 mb-1 line-clamp-1">
                {generation.prompt}
              </p>
              <div className="flex items-center gap-2 text-xs text-gray-500 dark:text-gray-400">
                {generation.seed && (
                  <div className="flex items-center gap-1">
                    <Hash className="w-3 h-3" />
                    <span>{generation.seed}</span>
                  </div>
                )}
                {generation.execution_time && (
                  <div className="flex items-center gap-1">
                    <Clock className="w-3 h-3" />
                    <span>{generation.execution_time}s</span>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}