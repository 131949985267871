import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from './Header';
import { GenerationForm } from './GenerationForm';
import { ImageDisplay } from './ImageDisplay';
import { ImageHistory } from './ImageHistory';
import { Gallery } from './Gallery';
import { generateImage, getTaskStatus } from '../lib/api/instasd/service';
import { getPromptWithTriggerWords } from '../lib/utils/prompt-utils';
import { saveGeneration, getUserGenerations, getUserState } from '../lib/firebase/db';
import type { LoraModel, TaskStatus, StyleOptions } from '../lib/types';
import { ASPECT_RATIOS, type AspectRatio } from '../lib/constants';
import { useAuth } from '../contexts/AuthContext';

const initialLoraModels: LoraModel[] = [
  { id: 'almarai', title: 'Almarai Product', value: 0, triggerWord: '3APF16' },
  { id: 'najdi', title: 'Najdi Architecture', value: 0, triggerWord: '1NAF18' },
  { id: 'saudi', title: 'Saudi', value: 0, triggerWord: '3SMF47' },
  { id: 'omani', title: 'Omani', value: 0, triggerWord: '1OMG64' },
  { id: 'omani-arch', title: 'Traditional Omani Architecture', value: 0, triggerWord: '1TOAF31' }
];

const POLLING_INTERVAL = 1000;

export function MainApp() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isApproved, setIsApproved] = useState(false);
  const [loading, setLoading] = useState(true);
  const [prompt, setPrompt] = useState('');
  const [seed, setSeed] = useState(Math.floor(Math.random() * 2147483647));
  const [aspectRatio, setAspectRatio] = useState<AspectRatio>(ASPECT_RATIOS.SQUARE);
  const [loraModels, setLoraModels] = useState<LoraModel[]>(initialLoraModels);
  const [generationLoading, setGenerationLoading] = useState(false);
  const [currentTask, setCurrentTask] = useState<TaskStatus | null>(null);
  const [error, setError] = useState('');
  const [generationHistory, setGenerationHistory] = useState<TaskStatus[]>([]);
  const [showGallery, setShowGallery] = useState(false);
  const [styles, setStyles] = useState<StyleOptions>({
    style1: 'No Style',
    style2: 'No Style',
    style3: 'No Style',
    style4: 'No Style'
  });

  useEffect(() => {
    async function checkApproval() {
      if (!user) {
        navigate('/login');
        return;
      }

      try {
        const userState = await getUserState(user.uid);
        if (!userState || userState.status !== 'approved') {
          navigate('/pending-approval');
          return;
        }
        setIsApproved(true);
        setLoading(false);
      } catch (error) {
        console.error('Error checking user approval:', error);
        navigate('/login');
      }
    }

    checkApproval();
  }, [user, navigate]);

  useEffect(() => {
    const updatedPrompt = getPromptWithTriggerWords(prompt, loraModels);
    if (updatedPrompt !== prompt) {
      setPrompt(updatedPrompt);
    }
  }, [loraModels]);

  useEffect(() => {
    if (user && isApproved) {
      getUserGenerations(user.uid)
        .then(generations => setGenerationHistory(generations))
        .catch(error => console.error('Error loading history:', error));
    }
  }, [user, isApproved]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (currentTask?.task_id && generationLoading && user) {
      intervalId = setInterval(async () => {
        try {
          const status = await getTaskStatus(currentTask.task_id);
          
          if (!generationLoading) {
            clearInterval(intervalId);
            return;
          }

          setCurrentTask(status);

          if (status.status === 'COMPLETED' || status.image_urls?.length > 0) {
            setGenerationLoading(false);
            clearInterval(intervalId);
            
            const completeStatus: TaskStatus = {
              ...status,
              prompt,
              seed,
              loraModels,
              styles,
              aspectRatio
            };
            
            await saveGeneration(user.uid, completeStatus, loraModels, styles, aspectRatio);
            setGenerationHistory(prev => [completeStatus, ...prev]);
          } else if (status.status === 'FAILED' || status.error) {
            setError(status.error || 'Image generation failed. Please try again.');
            setGenerationLoading(false);
            clearInterval(intervalId);
          }
        } catch (err) {
          const message = err instanceof Error ? err.message : 'Failed to check generation status';
          console.error('Status check failed:', message);
          setError(message);
          setGenerationLoading(false);
          clearInterval(intervalId);
        }
      }, POLLING_INTERVAL);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [currentTask?.task_id, generationLoading, user, prompt, seed, loraModels, styles, aspectRatio]);

  const handleGenerate = async () => {
    if (!prompt.trim()) {
      setError('Please enter a prompt');
      return;
    }

    if (!user) {
      setError('Please sign in to generate images');
      return;
    }

    try {
      setGenerationLoading(true);
      setError('');
      setCurrentTask(null);
      
      const result = await generateImage({
        prompt,
        seed,
        loraModels,
        aspectRatio,
        styles
      });

      setCurrentTask({
        ...result,
        prompt,
        seed,
        loraModels,
        styles,
        aspectRatio
      });
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to generate image';
      setError(message);
      console.error('Generation failed:', err);
      setGenerationLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-rmz-darker flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-rmz-primary"></div>
      </div>
    );
  }

  if (!isApproved) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-rmz-darker text-gray-900 dark:text-white transition-colors">
      <Header onShowGallery={() => setShowGallery(true)} onShowCreate={() => setShowGallery(false)} />
      <main className="container mx-auto px-4 py-8">
        {showGallery ? (
          <Gallery generations={generationHistory} />
        ) : (
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
            <div className="lg:col-span-4">
              <GenerationForm
                prompt={prompt}
                setPrompt={setPrompt}
                seed={seed}
                setSeed={setSeed}
                aspectRatio={aspectRatio}
                setAspectRatio={setAspectRatio}
                loraModels={loraModels}
                setLoraModels={setLoraModels}
                styles={styles}
                setStyles={setStyles}
                loading={generationLoading}
                error={error}
                onGenerate={handleGenerate}
              />
            </div>

            <div className="lg:col-span-6">
              <ImageDisplay
                imageUrl={currentTask?.image_urls?.[0]}
                prompt={currentTask?.prompt || prompt}
                seed={seed}
                loading={generationLoading}
                progress={currentTask?.completed_steps ? 
                  Math.min(Math.round((currentTask.completed_steps / (currentTask.estimated_steps || 20)) * 100), 100) : 0}
                progressMessage={currentTask?.progress_message}
                loraModels={loraModels}
                styles={styles}
                aspectRatio={aspectRatio}
              />
            </div>

            <div className="lg:col-span-2">
              <ImageHistory generations={generationHistory} />
            </div>
          </div>
        )}
      </main>
    </div>
  );
}